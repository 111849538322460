import React from "react";
import { efbet } from "../assetsExport";

const EfbetAd = ()=> {
    return(
        <section>
            <div className="efbet">
                <a href="https://cdn1.efbet.com/efbet/landingpage/index_BG.html?btag=a_9298b_1059c_&utm_source=gerena&utm_medium=cpm&utm_campaign=logo" target="_blank">
                    <img src={efbet} alt="" />
                </a>
            </div> 
        </section> 
    )
};

export default EfbetAd;